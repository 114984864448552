import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';
import useSiteTree from 'hooks/useSiteTree';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import ListTree from 'components/ListTree';

const SitemapPage: FC<SitemapTypes.SitemapProps> = ({
  data: {
    sitemap: { urls, seo },
  },
}) => {
  const { title, description, keywords } = seo;

  const {
    currentLang: [currentLangCode],
    defaultLang: [defaultLangCode],
  } = useCurrentLang();

  const tree = useSiteTree({
    lang: currentLangCode,
    defaultLang: defaultLangCode,
  });

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />

      <h1>{title}</h1>

      <ListTree items={tree} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    sitemap(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
    }
  }
`;

export default SitemapPage;
